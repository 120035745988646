import React from "react";
import Layout from "../components/layout";
import ContactForm from "../components/contactForm";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql, Link } from "gatsby";
import { MdLocationOn, MdPhone, MdEmail } from "react-icons/md";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button, Card } from "react-bootstrap";
import parse from "html-react-parser";
import SafeHtmlParser from "../components/safe-html-parser";
import ReviewSectionHome from "../components/review-section";
import NewsletterBanner from "../components/newsletter-banner";

const BlogPage = () => {
	const data = useStaticQuery(graphql`
		query {
			wpBlogPage {
				blogPageFields {
					ctaBannerSection {
						title
					}
					heroSection {
						tagline
						heading
						description
					}
					reviewSection {
						title
						description
						bottomSentence
						reviews {
							review
							name
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
				}
				seoFields {
					metaTitle
					opengraphTitle
					opengraphDescription
					metaDescription
					image {
						altText
						link
						localFile {
							childImageSharp {
								original {
									height
									width
								}
							}
						}
					}
				}
			}
			allWpPost(
				sort: { fields: dateGmt, order: DESC }
				filter: { title: { ne: "Website Images" } }
			) {
				nodes {
					slug
					excerpt
					title
					dateGmt
					blogFields {
						numberOfMinRead
						featuredImage {
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
							altText
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const m1Img = data.m1Img?.localFile.childImageSharp.gatsbyImageData;
	const m2Img = data.m2Img?.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Blog",
				item: {
					url: `${siteUrl}/blog`,
					id: `${siteUrl}/blog`,
				},
			},
		],
	};

	//  const html = `<form
	//     action="https://formspree.io/f/"
	//     method="POST">
	//   <div class="form-group">
	//       <input class="form-control bg-light border-0 py-5" placeholder="Name" type="text" name="name">
	//   </div>
	//   <div class="form-group">
	//       <input class="form-control bg-light border-0 py-5" placeholder="Email Address" type="email" name="_replyto">
	//   </div>
	//   <div class="form-group">
	//       <input class="form-control bg-light border-0 py-5" placeholder="Telephone Number" type="text" name="telephone">
	//   </div>
	//   <div class="form-group">
	//    <textarea class="form-control bg-light border-0 py-5" rows="3" name="message" placeholder="Message"></textarea>
	//   </div>
	//   // Send Button
	//   <div class="g-recaptcha my-3" data-sitekey=""></div>
	//   <button class="btn btn-outline-primary mt-3" id="contact-send-btn" type="submit">Send Message</button>
	//   </form>`

	return (
		<Layout>
			<Helmet>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={data.wpBlogPage.seoFields.metaTitle}
				description={data.wpBlogPage.seoFields.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/blog`,
					title: data.wpBlogPage.seoFields.opengraphTitle,
					description: data.wpBlogPage.seoFields.opengraphDescription,
					images: [
						{
							url: `${data.wpBlogPage.seoFields.image?.link}`,
							width: `${data.wpBlogPage.seoFields.image?.localFile.childImageSharp.original.width}`,
							height: `${data.wpBlogPage.seoFields.image?.localFile.childImageSharp.original.height}`,
							alt: `${data.wpBlogPage.seoFields.image?.altText}`,
						},
					],
				}}
			/>
			<div style={{ overflow: "hidden" }}>
				<section className="">
					<Container>
						<Row>
							<Col className="text-center  mb-5 mt-5">
								<p
									style={{ fontSize: "90%" }}
									className=" pb-0 mb-1 Jakarta-Bold text-heading-color"
								>
									{data.wpBlogPage.blogPageFields.heroSection.tagline}
								</p>
								<SafeHtmlParser
									htmlContent={
										data.wpBlogPage.blogPageFields.heroSection.heading
									}
								/>
								<p className=" pt-3">
									{data.wpBlogPage.blogPageFields.heroSection.description}
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="pb-5 pb-lg-9">
					<Container>
						<Row className="g-5 h-100 ">
							{data.allWpPost.nodes.map((post) => (
								<Col style={{ minHeight: "100%" }} md={6} xl={4}>
									<Link
										className="text-decoration-none"
										to={`/blog/${post.slug}`}
									>
										<div className="gradient-border-blog h-100">
											<Card className="h-100 position-relative p-2 blog-card">
												<Card.Img
													style={{ maxHeight: "254px", borderRadius: "14px" }}
													as={GatsbyImage}
													variant="top"
													image={
														post.blogFields.featuredImage.localFile
															.childImageSharp.gatsbyImageData
													}
													alt={post.blogFields.featuredImage.altText}
												/>
												<Card.Body className="ps-0 pb-3 pt-4">
													<Card.Title className="text-heading-color fs-6">
														{post.title}
													</Card.Title>
													<Card.Text
														style={{ fontSize: "90%" }}
														className="text-heading-color"
													>
														{post.dateGmt
															? new Date(post.dateGmt).toLocaleDateString(
																	"en-GB",
																	{
																		day: "2-digit", // numeric day of the month
																		month: "short", // abbreviated month
																		year: "numeric", // full numeric year
																	}
															  )
															: ""}
														<span className="mx-2">•</span>{" "}
														{post.blogFields.numberOfMinRead} min read
													</Card.Text>
												</Card.Body>
											</Card>
										</div>
									</Link>
								</Col>
							))}
						</Row>
					</Container>
				</section>
				<section className="bg-med-grey py-4 py-lg-5">
					<ReviewSectionHome
						title={data.wpBlogPage.blogPageFields.reviewSection.title}
						subtitle={data.wpBlogPage.blogPageFields.reviewSection.description}
						testimonials={data.wpBlogPage.blogPageFields.reviewSection.reviews}
						bottomText={
							data.wpBlogPage.blogPageFields.reviewSection.bottomSentence
						}
					/>
				</section>
				<NewsletterBanner
					title={data.wpBlogPage.blogPageFields.ctaBannerSection.title}
				/>
			</div>
		</Layout>
	);
};

export default BlogPage;
